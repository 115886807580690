import React from 'react'
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import {Col} from 'react-bootstrap'
import benifitsimg from '../../images/benefits.png'
import { HiOutlineDocumentChartBar } from "react-icons/hi2";
import { RxLaptop } from "react-icons/rx";
import { FaRegClipboard } from "react-icons/fa";
import { LiaNetworkWiredSolid } from "react-icons/lia";

const Benifits = ({data}) => {
    const isMobile = useMediaQuery({ query: `(max-width: 991px)` });
    return (
        <div className='benifit-section'>
            <div class="space-60"></div>
            {
                isMobile &&
                <div class="ot-heading">
                    <span class="is_highlight">by use case</span>
                    <h3 class="main-head">Efficient asset tracking improves maintenance scheduling accuracy</h3>
                </div>
            }
            <div className='row w-100'>
                <Col lg={5} md={12} sm={12} >
                    <img className='benefits-image' src={benifitsimg} alt=""></img>
                </Col>
                <Col lg={7} md={12} sm={12} >

                    <div class="our-benefits-detail">
                        {
                            !isMobile &&
                            <div class="ot-heading">
                                <span class="is_highlight">by use case</span>
                                <h3 class="main-head">Efficient asset tracking improves maintenance scheduling accuracy</h3>
                            </div>
                        }

                        <div class="mt-20"></div>
                        {
                            data.map((ele, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <div className="space-30"></div>
                                        <div className="space-5"></div>
                                        <NavLink className="text-decoration-none" to={`/detail/${ele.slug}`}>
                                            <div className="icon-box icon-box-2 benefits-icon-box">
                                                <div className="icon-benifits icon-main">
                                                    {ele.slug === 'automation-of-asset-inventory' &&
                                                        <HiOutlineDocumentChartBar className="icon-image" />
                                                    }
                                                    {ele.slug === 'digitalization-of-fixed-assets-management' &&
                                                        <RxLaptop className="icon-image" />
                                                    }
                                                    {ele.slug === 'accurate-fixed-asset-register' &&
                                                        <LiaNetworkWiredSolid className="icon-image" />
                                                    }
                                                    {ele.slug === 'automate-physical-tracking-of-assets' &&
                                                        <FaRegClipboard className="icon-image" />
                                                    }
                                                </div>
                                                <div className="content-box">
                                                    <h5 className="title-box pt-0">{ele.title}
                                                    </h5>
                                                    <p dangerouslySetInnerHTML={{ __html: ele.description }} />
                                                </div>
                                            </div>
                                        </NavLink>

                                    </React.Fragment>
                                )
                            })
                        }


                    </div>

                </Col>
            </div>
        </div>
    )
}

export default Benifits
