import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom';
import logo from '../../images/logo/logo_footer.png'
import { FaBars, FaArrowRightLong } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
import { setToggleTrue, setToggleFalse, toggleShowSubmenu } from '../../redux/Slice/changeState';


const MobileNavbar = () => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state);
  return (
    <div className="header_mobile coporate-style">
      <div className="container">
        <div className="row">
          <div className="col-5">
            <div className="mobile_logo">
              <a href="/">
                <img src={logo} alt="Irax" />
              </a>
            </div>
          </div>
          <div className="col-7 align-self-center text-end">
            <div className="octf-btn-cta">
              <div className="octf-menu-mobile octf-cta-header text-start">
                <div id="mmenu-toggle" className={`mmenu-toggle ${state.toggle.hastoggle && 'active'}`}>
                  <button onClick={() => dispatch(setToggleTrue())}><FaBars className='toggle-bar' /></button>
                </div>
                <div className="site-overlay mmenu-overlay"></div>
                <div id="mmenu-wrapper" className={`mmenu-wrapper on-right ${state.toggle.hastoggle && 'mmenu-open'}`}>
                  <div className="mmenu-inner">
                    <button className="mmenu-close" onClick={() => dispatch(setToggleFalse())}><FaArrowRightLong /></button>
                    <div className="mobile-nav">
                      <ul id="menu-mobile-main-menu" className="mobile_mainmenu none-style">
                        {
                          state.master.navigation != null && state.master.navigation.result.length > 0 && ((state.master.navigation.result).filter(ele => ele.list_key === 'main_menu')).map((ele, i) => {
                            return (
                              <li key={i} className={`${ele.hasOwnProperty("children") ? "menu-item-has-children" : ""}`}>
                                {
                                  ele.hasOwnProperty("children") ?

                                    <NavLink onClick={() => { dispatch(toggleShowSubmenu()) }}>{ele.name} <IoIosArrowForward /></NavLink>
                                    :
                                    <NavLink onClick={() => { dispatch(setToggleFalse()) }} to={`${ele.hasOwnProperty("children") ? "" : ele.url}`}>{ele.name}</NavLink>
                                }

                                {
                                  ele.hasOwnProperty("children") &&

                                  <ul className="sub-menu" style={{ display: state.toggle.showsubmenu ? 'block' : '', }}>
                                    {ele.children.map((element, ind) => {
                                      return (
                                        <React.Fragment key={ind}>
                                          {
                                            ele.name == "Products" ?
                                              <li><NavLink onClick={() => { dispatch(setToggleFalse()) }} to={element.url} target="_parent">{element.name}</NavLink></li>
                                              :
                                              <li><NavLink onClick={() => { dispatch(setToggleFalse()) }} to={element.url}>{element.name}</NavLink></li>

                                          }
                                        </React.Fragment>
                                      )
                                    })}

                                  </ul>
                                }
                              </li>
                            )
                          })
                        }

                      </ul>
                    </div>
                    <div className='space-20'></div>
                    <div className="header-buttion-signin-up">
                      <div className="header-button-signin">
                        <NavLink className={"text-decoration-none "} to="https://login.shopersbay.com/shopersbay" target="_blank">
                          Sign In
                        </NavLink>
                      </div>
                      <div className="header-button-signup">
                        <NavLink className={"text-decoration-none"} to="https://login.shopersbay.com/signup?q=shopersbay" target="_blank">
                          Sign Up
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileNavbar
