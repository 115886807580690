import React from 'react'
import * as common from '../component/common'
import { useSelector } from 'react-redux';

const Home = () => {
  // const {homedata, faqData} = useSelector(state => state.master.homeData);
  const { homedata, faqData, subscriptionData } = useSelector(state => ({
    homedata: state.master.homeData,
    faqData: state.master.faqData,
    subscriptionData: state.master.subscriptiondata,
  })); 
  console.log("home", subscriptionData)
  return (
    <>
      <common.Slider data={homedata != null && homedata.data.postcategorydataList.Feature} />
      {
      homedata != null && (
        <>
          {homedata.data.postcategorydataList.hasOwnProperty("HomeAbout") && (
            <common.OneImage data={homedata.data.postcategorydataList.HomeAbout[0]} />
          )}
          {homedata.data.postcategorydataList.hasOwnProperty("HomeAboutFeature") && (
            <common.Feature data={homedata.data.postcategorydataList.HomeAboutFeature} />
          )}
          {homedata.data.postcategorydataList.hasOwnProperty("Benifits") && (
            <common.Benifits data={homedata.data.postcategorydataList.Benifits} />
          )}
          {/* <common.CallToAction /> */}
          {
            subscriptionData != null && subscriptionData.data.length > 0 &&  <common.Subscription />
          }
          <div className='space-60'></div>
          {
            faqData!=null && faqData.pagelist.length > 0 && <common.Faqs />
          }
          
          <div className='space-60'></div>
          <common.ContactComponent />
        </>
      )
      }
      
    </>
  )
}

export default Home
