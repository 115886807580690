import React from 'react'
import { useSelector } from 'react-redux';
import { Container, Row, Col } from "react-bootstrap"
import {DocHeader, DocSidebar} from '../common';

const DocLayout = ({ children }) => {
    const state = useSelector((state) => state.toggle);
    const homedata = useSelector((state) => state.master.homeData)
  return (
    <>
      <DocHeader />
      <DocSidebar />
      <div className='doc-div'>
        {children}
        
      </div>

    </>
  )
}

export default DocLayout
