import React from 'react'
import { NavLink } from 'react-router-dom'
import { MdKeyboardArrowRight } from "react-icons/md";
import bg from '../../images/27362.jpg'

const BlogSlider = ({data}) => {
  return (
    <div id="content" className="site-content">
        <div className="page-header flex-middle page-header-service" style={{backgroundImage: `url(${bg})`}}>
            <div className="container">
                <div className="inner flex-middle">
                    <h1 className="page-title">{data.hasOwnProperty("title") && data.title}</h1>
                    <ul id="breadcrumbs" className="breadcrumbs none-style">
                        <li><NavLink to="/">Home</NavLink><MdKeyboardArrowRight /></li>
                        <li className="active">{data.hasOwnProperty("title") && data.title}</li>
                    </ul>    
                </div>
            </div>
        </div>
    </div>
  )
}

export default BlogSlider
